<template>
  <el-container>
    <el-aside width="115px">
      <div class="headImg">
        <img :src="userInfo.avatar" alt="" />
        <el-popover
          v-model:visible="changeLineFlag"
          placement="right-start"
          :width="110"
          trigger="click"
        >
          <template #>
            <div
              v-for="(item, index) in 3"
              @click="changeLineStatus(index)"
              :key="index"
              class="changeStatus"
            >
              <span
                :style="{
                  background: index === 0 ? '#19B200' : index === 1 ? '#F10000' : '#999999',
                  width: '9px',
                  height: '9px'
                }"
                class="isLine"
              ></span>
              <span>{{ index == 0 ? '在线' : index == 1 ? '忙碌' : '离线' }}</span>
              <i v-if="onlineStatus === index" class="iconfont icon-duigou"></i>
            </div>
          </template>
          <template #reference>
            <div class="lineStatus">
              <span
                :style="{
                  background:
                    onlineStatus === 0 ? '#19B200' : onlineStatus === 1 ? '#F10000' : '#999999'
                }"
                class="isLine"
              ></span>
              <span :style="{ color: '#999999' }" class="lineTxt">{{
                onlineStatus == 0 ? '在线' : onlineStatus == 1 ? '忙碌' : '离线'
              }}</span>
            </div>
          </template>
        </el-popover>
      </div>
      <el-menu
        default-active="0"
        class="el-menu-vertical-demo"
        background-color="#010e27"
        text-color="#fff"
        :router="true"
        active-text-color="#fff"
      >
        <el-menu-item
          :class="[activePath == item.path ? 'isactive' : '']"
          :index="item.path"
          v-for="(item, index) in firstRoute"
          :key="index"
        >
          <img :src="menuImgUrl + item.name + '.png'" alt="" />
          {{ item.meta.name }}</el-menu-item
        >
      </el-menu>
      <div @click="outlogin" class="outlogin">
        <i class="iconfont icon-tuichudenglu"></i>退出登录
      </div>
    </el-aside>
    <el-container>
      <el-header>
        <div class="headerLeft">
          <div class="notice">
            <img :src="imgUrl + 'notice.png'" alt="" />
            公告：
          </div>
          <div v-if="notceList.length == 1">
            {{ notceList[0].title }}
          </div>
          <el-carousel
            v-else
            height="14px"
            direction="vertical"
            arrow="never"
            indicator-position="none"
            :interval="2000"
          >
            <el-carousel-item v-for="(item, index) in notceList" :key="index">
              {{ item.title }}
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="headerRight">
          <div>
            <img :src="imgUrl + 'manual.png'" alt="" />
            <span>使用手册</span>
          </div>
          <div>
            <img :src="imgUrl + 'helpcenter.png'" alt="" />
            <span>帮助中心</span>
          </div>
          <div>
            <img :src="imgUrl + 'feedback.png'" alt="" />
            <span>提交反馈</span>
          </div>
        </div>
      </el-header>
      <el-main>
        <router-view v-slot="{ Component }" :key="$router.fullPath">
          <keep-alive>
            <component
              ref="IsInstantSession"
              :is="Component"
              v-if="$route.path === '/instantSession'"
            />
          </keep-alive>
          <component :is="Component" v-if="$route.path !== '/instantSession'" />
        </router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { reactive, toRefs, watch, provide, ref, nextTick } from 'vue'
import { menuImgUrl, imgUrl } from '@/util/baseUrl.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { socketUrl } from '../util/baseUrl'
import { post } from '../util/axios'
import { api } from '../util/api'
import { addChatList } from '../util/common'
let socketAll = null
export default {
  setup(props, context) {
    const $route = useRoute()
    const $router = useRouter()
    const firstRoute = reactive($router.getRoutes().filter((item) => item.meta.hierarchy == 1))
    const notceList = [
      { title: '客服模块新增智能助手功能，助力商家快速回复111' },
      { title: '客服模块新增智能助手功能，助力商家快速回复222' },
      { title: '客服模块新增智能助手功能，助力商家快速回复333' },
      {
        title:
          '客服模块新增智能助fasefafwaeffegargagrafraerafeaefawefghgagr手功能，助力商家快速回复444'
      },
      { title: '客服模块新增智能助手功能，助力商家快速回复555' },
      { title: '客服模块新增智能助手功能，助力商家快速回复666' },
      { title: '客服模块新增智能助手功能，助力商家快速回复777' }
    ]
    const data = reactive({
      activePath: '',
      changeLineFlag: false,
      onlineStatus: 0
    })
    // 存储所有的聊天激励
    let chatAllRecord = ref()
    const IsInstantSession = ref(null)
    if (!localStorage.getItem('chatList')) {
      // 本地没有聊天记录
      chatAllRecord.value = {}
      post(api.getChatRecord).then((res) => {
        if (res.code == 0) {
          res.result.map((item) => {
            addChatList(chatAllRecord, item)
          })
        } else {
          ElMessage.error(res.msg)
        }
      })
    } else {
      chatAllRecord.value = JSON.parse(localStorage.getItem('chatList'))
    }
    // 用户信息
    const userInfo = JSON.parse(sessionStorage.getItem('info'))
    // 用户token
    const token = userInfo.token
    // 接收到的新消息
    const newMsg = ref()
    // socket对象
    socketAll = ref()
    // 是否是离线状态
    let outLine = Number(sessionStorage.getItem('outLine'))
    // 判断账号的在线状态
    if (outLine) {
      data.onlineStatus = 2
    } else {
      socketIo(token, newMsg, chatAllRecord, socketAll)
      data.onlineStatus = userInfo.is_busy
    }
    // 改变登录状态
    const changeLineStatus = (index) => {
      if (index == 0 || index == 1) {
        post(api.setBusy, {
          is_busy: index
        }).then((res) => {
          if (res.code == 0) {
            if (data.onlineStatus == 2) {
              if (IsInstantSession.value) {
                IsInstantSession.value.getServiceList()
                IsInstantSession.value.getbeAccessedList()
              }
              socketIo(token, newMsg, chatAllRecord, socketAll)
            }
            data.onlineStatus = index
            data.changeLineFlag = false
            userInfo.is_busy = index
            sessionStorage.setItem('info', JSON.stringify(userInfo))
            sessionStorage.removeItem('outLine')
          } else {
            ElMessage.error(`${res.msg}`)
          }
        })
      } else {
        data.onlineStatus = index
        data.changeLineFlag = false
        sessionStorage.setItem('outLine', 1)
        if (socketAll.value) {
          socketAll.value.close()
          socketAll.value = ref()
        }
      }
    }
    // 将socket对象传往子级
    provide('socketAll', socketAll)
    // 将socket发来的新数据传往子级
    provide('newMsg', newMsg)
    //
    const outlogin = () => {
      ElMessageBox.confirm('确认退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          post(api.logout).then((res) => {
            if (res.code === 0) {
              if (socketAll.value) {
                socketAll.value.close()
                socketAll.value = ref()
              }
              // 清除缓存的该账号信息
              sessionStorage.clear()
              // 转去登陆页
              $router.push('/login')
              ElMessage.success('已成功退出登录')
            } else {
              ElMessage.success(`${res.msg}`)
            }
          })
        })
        .catch(() => {})
    }
    // 监听路由处理菜单高亮
    watch(
      $route,
      (val) => {
        nextTick(() => {
          if (val.matched.length >= 2) {
            data.activePath = val.matched[1].path
          }
        })
      },
      {
        deep: true,
        immediate: true
      }
    )
    return {
      ...toRefs(data),
      firstRoute,
      menuImgUrl,
      imgUrl,
      notceList,
      userInfo,
      changeLineStatus,
      outlogin,
      IsInstantSession
    }
  }
}
const socketIo = (token, newMsg, chatAllRecord, socketAll) => {
  if (typeof WebSocket === 'undefined') {
    ElMessage.error('您的浏览器不支持socket,请先升级浏览器')
    return
  }
  socketAll.value = new WebSocket(socketUrl + token)
  socketAll.value.onopen = (val) => {
    console.log('开启socket服务')
  }
  socketAll.value.onmessage = (res) => {
    console.log(JSON.parse(res.data))
    let data = JSON.parse(res.data)
    if (data.action === 104 || data.action === 105) {
      newMsg.value = data
      if (data.action === 104) {
        addChatList(chatAllRecord, data)
      }
    }
  }
  socketAll.value.onclose = () => {
    console.log('关闭socket服务')
  }
  socketAll.value.onerror = () => {
    console.log('socket服务出错，重启服务')
    socketAll.value.close()
    socketIo(token, newMsg, chatAllRecord, socketAll)
  }
}
</script>

<style lang="less" scoped>
.flexLayout(@direction:row, @main:space-between, @second:center) {
  display: flex;
  flex-direction: @direction;
  justify-content: @main;
  align-items: @second;
}
.isLine {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 4px;
}
.changeStatus {
  .flexLayout(row,flex-start);
  cursor: pointer;
  position: relative;
  padding-left: 15%;
  i {
    margin-left: 5px;
    position: absolute;
    left: 65%;
  }
}
.changeStatus:nth-child(2) {
  margin: 25px 0;
}
.el-popover.el-popper {
  min-width: 110px !important;
}
.el-container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  .el-aside {
    background: #010e27;
    &::-webkit-scrollbar {
      width: 0px;
    }
    .headImg {
      width: 100%;
      height: 100px;
      padding: 16px 0 12px 0;
      .flexLayout(column);
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
      }
      .lineStatus {
        margin-top: 8px;
        cursor: pointer;
        .flexLayout();
      }
    }

    .el-menu {
      width: 100%;
      height: calc(100% - 200px);
      border: 0;
      .el-menu-item {
        height: 54px;
        line-height: 54px;
        user-select: none;
        img {
          width: 18px;
          height: 18px;
        }
      }
      .isactive {
        background: #1467ff !important;
      }
    }
    .outlogin {
      color: #fff;
      width: 100%;
      text-align: center;
      cursor: pointer;
      user-select: none;
    }
  }
  .el-header {
    min-width: 1200px;
    .flexLayout();
    height: 49px !important;
    border-bottom: 1px solid #f0f0f0;
    .headerLeft {
      .flexLayout();
      .notice {
        .flexLayout();
        img {
          margin-right: 6px;
        }
      }
      .el-carousel {
        width: 400px;
        .el-carousel__item {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .headerRight {
      .flexLayout();
      & > div {
        cursor: pointer;
        .flexLayout();
        margin-right: 25px;
        img {
          margin-right: 4px;
        }
      }
    }
  }
  .el-main {
    height: calc(100vh - 50px);
    padding: 0 !important;
    overflow-y: hidden;
    overflow-x: hidden;
  }
}
</style>
